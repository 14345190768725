const {VUE_APP_PPU_LETTER} = process.env;

export function validateImageLarge(image) {
  if (!image) return;

  if (image && !image.l) {
    return image.url;
  }
  return image.l.uri;
}

export function validateImageSmall(image) {
  if (!image) return;
  if (image && !image.s) {
    return image.url;
  }
  return image.s.uri;
}

export function validateImageFull(image) {
  if (!image) return;

  return image.url;
}

export function precioFix(val) {
  if (!val) {
    return '';
  }
  return '$' + parseInt(val).toLocaleString('de-DE');
}

export function kilometrajeFix(val) {
  return parseInt(val).toLocaleString('de-DE');
}

export function imagesFixes(datos) {
  if (!datos) return [];

  const video = datos && 'video' in datos ? datos.video : [];

  return [
    ...video.map((v) => v.value),
    datos.Foto_portada.value,
    ...datos.Fotos_Externas,
    ...datos.Fotos_internas,
  ];
}

export function isValidLicensePlate(plate) {
  if (!plate || typeof plate !== 'string') return null;
  if (plate.length < 6) return false;

  const firstLetter = plate[0].toUpperCase();

  return firstLetter >= VUE_APP_PPU_LETTER.toUpperCase();
}

export function oldPlate(plate) {
  if (!plate || typeof plate !== 'string') return null;
  if (plate.length < 6) return false;

  const regex = /[0-9]{4}$/;

  return regex.test(plate);
}
