<template>
  <v-row align="center" justify="center" no-gutters class="mt-12">
    <v-col v-for="(title, index) of titles" :key="index" cols="12">
      <h1
        v-if="$vuetify.breakpoint.smAndDown"
        class="text-center title--text font-weight-bold text-h4"
      >
        {{ title }}
      </h1>
      <h1 v-else class="text-center title--text font-weight-regular text-h3">
        {{ title }}
      </h1>
      <v-col cols="12" class="mb-8">
        <h3
          v-for="(title, index) of subtitles"
          :key="index"
          class="text-center title--text font-weight-light my-3"
        >
          {{ title }}
        </h3>
      </v-col>
    </v-col>
    <v-col cols="11" md="8" xl="6" lg="6">
      <formulariopetersen-compra :renewal="renewal" />
    </v-col>
  </v-row>
</template>

<script>
import FormulariopetersenCompra from '@/components/core/formulariopetersenCompra';
import {subtitulos} from '@/utils/general';

export default {
  name: 'compramosAuto',
  components: {FormulariopetersenCompra},
  props: {
    consignment: {
      type: Boolean,
      default: false,
    },
    renewal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    subtitles: subtitulos,
  }),
  computed: {
    titles() {
      return !this.renewal
        ? ['- Te pagamos al instante -']
        : ['- Gana tiempo y seguridad dejando tu auto en parte de pago -'];
    },
  },
};
</script>

<style scoped></style>
